import logo from './logo.svg';
import './App.css';
import moment from 'moment';

var bday = moment("2024-08-21T00:00:00")
var today = moment()
var count = Math.ceil(moment.duration(bday.diff(today)).asDays());



function App() {
  return (
    <div className="container">
      <div className="inside">
        {count}
      </div>
    </div>
  );
}

export default App;
